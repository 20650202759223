<template>
	<div class="province_city">
		<el-form class="province_city_form">
			<el-select :popper-append-to-body="false" class=" select province_selecter" v-model="province" @change="getProvince"
			 placeholder="省份">
				<el-option :label="item" :value="item" v-for="(item,index) in provinceList" :key="item"></el-option>
			</el-select>
			<el-select class="select" v-model="city" @change="changeCity" placeholder="城市">
				<el-option v-for="(item,index) in cityList" :key="item" :label="item" :value="item"></el-option>
			</el-select>
		</el-form>

	</div>
</template>

<script type="text/javascript">
	import provinceCity from '../area/provinceCity.json'
	export default {
		name: 'provinceCity',
		props: {
			defaultProvince: {
				type: String,
				default: ''
			},
			defaultCity: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				province: "",
				city: "",
				provinceList: [], //获取的一级数组数据
				cityList: [] //获取的二级数组数据
			};
		},
		created() {
			if(this.defaultCity) this.city = this.defaultCity
			if(this.defaultProvince) this.province = this.defaultProvince
			this.provinceList = provinceCity.map((item) => {
				return item.name
			})
			provinceCity.forEach(item => {
				if (item.name == this.province) {
					item.city.forEach((cityItem) => {
						this.cityList.push(cityItem.name)
					})
				}
			});
		},
		computed: {

		},
		methods: {
			clearArea() {
				this.city = "",
				this.province = ""
			},
			getProvince() {
				this.city = "";
				this.$emit("handleChange", {
					province: this.province,
					city: this.city
				})
				this.cityList = [];
				provinceCity.forEach(item => {
					if (item.name == this.province) {
						item.city.forEach((cityItem) => {
							this.cityList.push(cityItem.name)
						})
					}
				});
			},
			changeCity(val) {
				this.$emit("handleChange", {
					province: this.province,
					city: this.city
				})
			}
		}
	}
</script>

<style lang="less">
	.province_city {
		.el-input__inner {
			border: none;
			font-size: 1.9rem;
		}

		.el-input--suffix .el-input__inner {
			padding: 0;
			text-align: center;
		}

		.el-select-dropdown__item {
			font-size: 1.6rem;
		}

		.el-input__suffix {
			margin-left: 1rem;
			// right: 3rem;
		}

		.province_city_form {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.select {
			width: 50%;
			height: 4rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.province_selecter {
			border-right: 1px solid #D8D8D8;
		}
	}
</style>
