<template>
  <div class="video_mask" @click.stop="handeleClose">
    <div class="content">
      <video
        @click="stopPropagation"
        id="injectVideo"
        src="https://static.adamu.cn/sources/video/inject.mp4"
        class="video_tag"
        controls="controls"
      >
        当前浏览器不支持视频播放
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "videoMask",
  methods: {
    handeleClose() {
      this.$emit("closeVideo");
    },
    stopPropagation(e) {
      e.stopPropagation();
    },
  },
};
</script>

<style lang="less">
.video_mask {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .video_tag {
      width: 55%;
    }
  }
}
</style>
