<template>
  <div>
    <div class="video"
         v-if="list.length">
      <a v-for="(item, index) in list"
         :key="index"
         class="video_wrap"
         @click="openNewLink(item.zb_link)">
        <div class="video_item">
          <img class="video_item"
               :src="item.conver"
               alt />
          <div class="video_tips">
            <div class="video_date_time">
              <div class="video_date">{{item.date}}</div>
              <div class="video_time">{{item.time}}</div>
            </div>
            <div class="video_des"
                 v-if="fromMini">{{item.summary}}</div>
          </div>
          <div class="video_play"></div>
        </div>

        <div class="video_title">{{item.title}}</div>
        <div class="video_des"
             v-if="!fromMini">{{item.summary}}</div>
      </a>
    </div>
    <div v-else>内容正在上传中，尽情期待...</div>
  </div>
</template>

<script>
export default {
  name: "videoItem",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    fromMini: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {};
  },
  computed: {},
  mounted () {
    console.log("list", this.list);
  },
  methods: {
    openNewLink (link) {
      console.log("fromMini", this.fromMini)
      if (this.fromMini) {
        link = 'https://mp.weixin.qq.com/s?__biz=MzU5OTc1ODY5OA==&mid=100002204&idx=1&sn=8fda73043930375ff188e44d488b8342&chksm=7eb14b9949c6c28f86f90ac5eca63908b7c6285297a2eaf525fb26356bbf875cfbeafd658524'
      }
      window.open(link);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.video {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1.2rem;

  .video_wrap {
    width: 28%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    margin-bottom: 2rem;
  }

  .video_wrap:nth-child(n) {
    margin-right: 4rem;
  }

  .video_wrap:nth-child(2n) {
    margin-right: 4rem;
  }

  .video_wrap:nth-child(3n + 3) {
    margin-right: 0;
  }

  .video_item {
    display: flex;
    width: 100%;
    height: 11rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem 0.4rem 0 0;

    .video_play {
      width: 3rem;
      height: 3rem;
      position: absolute;
      z-index: 999;
      background: url("../assets/reg_icon_play.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }

  .video_tips {
    // height: 1.3rem;
    background: rgba(0, 28, 142, 0.56);
    border-radius: 0rem 0rem 0.4rem 0.4rem;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0rem;
    padding: 0.35rem 1rem;
    box-sizing: border-box;
    font-size: 0.5rem;

    font-weight: 400;
    color: #ffffff;
    line-height: 0.7rem;

    .video_date_time {
      display: flex;

      .video_date {
        margin-right: 0.6rem;
      }
    }
  }

  .video_title {
    margin: 0.8rem 0;
    font-size: 0.9rem;

    font-weight: 500;
    color: #333333;
    line-height: 1.3rem;
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .video_title:hover {
    color: red;
  }

  .video_des {
    margin-bottom: 1rem;
    font-size: 0.68rem;

    font-weight: 400;
    color: #999999;
    line-height: 1.2rem;
    padding: 0 1rem;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
