import axios from "axios";
let api = "https://gate.adamu.cn";
// let api = "https://gate.maiduote.com";
// let api = "http://172.16.1.77:7001";

// 获取医生模块
export async function getDoctorList(query = {}) {
  const resp = await axios.get(`${api}/pub/dzone/list`, {
    params: query,
  });
  return resp.data;
}

// 获取病例分享
export async function getExampleList(query = {}) {
  const resp = await axios.get(`${api}/pub/dbli/list`, {
    params: query,
  });
  return resp.data;
}

// 获取病例分享详情
export async function getExampleDetail(query = {}) {
  const resp = await axios.get(`${api}/pub/dbli/detail`, {
    params: query,
  });
  return resp.data;
}

// 获取热点文章
export async function getHotList(query = {}) {
  const resp = await axios.get(`${api}/pub/article/list`, {
    params: query,
  });
  return resp.data;
}

// 获取患者文章
export async function getPatientArticleList(query = {}) {
  const resp = await axios.get(`${api}/pub/pzone/wxlist`, {
    params: query,
  });
  return resp.data;
}

// 获取患者直播
export async function getPatientLiveList(query = {}) {
  const resp = await axios.get(`${api}/pub/pzone/livelist`, {
    params: query,
  });
  return resp.data;
}

// 根据省市搜索医院
export async function getHospitalList(query = {}) {
  const resp = await axios.get(
    `https://api.p.maiduote.com/biot/common/hospitals/geo-search`,
    {
      params: query,
    }
  );
  return resp.data;
}

// 获取疾病百科的内容
export async function getWikiList(query = {}) {
  const resp = await axios.get(`${api}/pub/kepu/list`, {
    params: query,
  });
  return resp.data;
}

// 提交购药咨询接口
export async function submitApplication(query, headers) {
  const resp = await axios.post(`${api}/ht/consult/css`, query, {
    headers,
  });
  return resp.data;
}
