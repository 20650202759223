<template>
  <div class="science_article_tab ql-editor"
       id="scienceArticle"
       v-if="contentList.length">
    <el-tabs v-model="renderActiveName"
             @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in contentList"
                   :key="index"
                   :label="item.title"
                   :name="index + ''">
        <div class="tab_content">
          <div class="des">
            <div class="content_item">
              <div v-html="item.content"></div>
            </div>
            <div v-if="showNext && index!=contentList.length - 1"
                 class="next_btn_wrap">
              <div class="next_btn"
                   @click="nextHandle">继续阅读：{{contentList[index+1].title}}</div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getParam } from "@/util.js";
export default {
  name: "scienceTab",
  props: {
    activeName: {
      type: String,
      default: "0"
    },
    showNext: {
      type: Boolean,
      default: true
    },
    contentList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      renderActiveName: "0"
    };
  },
  beforeCreate () { },
  created () {
    this.renderActiveName = this.activeName;
    console.log("contentList", this.contentList);
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event);
    },
    nextHandle () {
      // window.location.href = "#scienceArticle"
      let nextname = parseInt(this.renderActiveName) + 1;
      this.renderActiveName = nextname.toString();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='less'>
.science_article_tab {
  .tab_content {
    margin-top: 2rem;
  }
  .des {
    font-size: 1.1rem;

    font-weight: 400;
    color: #333333;
    line-height: 2rem;
    .content_item {
      margin-bottom: 2rem;
      .title {
        font-weight: 700;
      }
    }
  }

  .next_btn_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;

    .next_btn {
      display: inline-flex;
      padding: 1.2rem 2.5rem;
      font-size: 1.8rem;

      font-weight: 400;
      color: #4967dc;
      border-radius: 0.5rem;
      border: 1px solid #4967dc;
    }
  }
}
</style>
